/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  CardContent,
  Container,
  Grid,
  Typography,
  Hidden,
} from '@mui/material';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import { Wrapper } from '../components';
import useStyles from '../styles';

import getProjectDetails from '../firebase/getProjectDetails';

import { getAfterImages, getBeforeImages } from '../firebase/getImages';
import getVideos from '../firebase/getVideos';

function Video({
  videoSrcURL, videoTitle, text,
}) {
  const classes = useStyles();
  return (
    <Container className={classes.section} maxWidth="lg">
      <CardContent>
        <Typography variant="h3" align="center">{videoTitle}</Typography>
      </CardContent>
      <CardContent>
        <Typography align="center">{text}</Typography>
      </CardContent>
      <CardContent />
      <CardContent>
        <Hidden only="xs">
          <div className="video" style={{ display: 'flex', justifyContent: 'center' }}>
            <iframe
              width="800"
              height="314"
              src={`${videoSrcURL}?rel=0&controls=0`}
              title={videoTitle}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              modestbranding
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            className="video"
            style={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              maxWidth: '100vw',
            }}
          >
            <iframe
              height="314"
              src={`${videoSrcURL}?rel=0&controls=0`}
              title={videoTitle}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              modestbranding
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          </div>
        </Hidden>
      </CardContent>
      <CardContent />
    </Container>
  );
}

function Gallery({ heading, text, images }) {
  const classes = useStyles();

  if (!images) return <></>;
  return (
    <>
      <Container className={classes.section} maxWidth="xl">
        <CardContent />
        <Container>
          <CardContent>
            <Typography variant="h2" align="center">{heading}</Typography>
          </CardContent>
        </Container>
        <Container>
          <CardContent>
            <Typography variant="subtitle1" align="center">{text}</Typography>
          </CardContent>
        </Container>
        <CardContent />
        <ImageGallery items={images} lazyLoad />
        <CardContent />
        <CardContent />
      </Container>
    </>
  );
}

function BeforeGallery(props) {
  return (
    <Gallery heading="Before Gallery" {...props} />
  );
}

function AfterGallery(props) {
  const classes = useStyles();
  return (
    <div className={classes.blueBackground}>
      <Gallery heading="After Gallery" {...props} />
    </div>
  );
}

export default function ProjectPage({ location }) {
  const params = new URLSearchParams(location.search);
  const projectId = params.get('projectId');

  const { project } = getProjectDetails(projectId);
  const { beforeImages } = getBeforeImages(projectId);
  const { afterImages } = getAfterImages(projectId);
  const { videos } = getVideos(projectId);

  if (!project) return <></>;
  return (
    <Wrapper
      title={project.projectTitle}
      featuredImage={project.featuredImage}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={8}
      >
        <Grid item xs={12}>
          <BeforeGallery
            images={beforeImages}
            text={project.beforeText}
          />
        </Grid>
        {videos && (
          <Grid item>
            {videos.sort((a, b) => a.order - b.order).map((video) => (
              <Video videoSrcURL={video.videoUrl} videoTitle={video.title} text={video.text} />
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <AfterGallery
            images={afterImages}
            text={project.afterText}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
}
