/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { projectFirestore } from './firebase';

const getBeforeImages = (projectId) => {
  const [beforeImages, setBeforeImages] = useState([]);

  useEffect(() => {
    if (projectId && projectFirestore) {
      const unsub = onSnapshot(collection(projectFirestore, `/projects/${projectId}/before`), (snapshot) => {
        const images = [];
        snapshot.forEach((doc) => {
          images.push({
            ...doc.data(),
            id: doc.id,
            original: doc.data().imageUrl,
            thumbnail: doc.data().imageUrl,
          });
        });
        setBeforeImages(images);
      });
      return () => unsub();
    }
    return null;
  }, [projectId]);

  return { beforeImages };
};

const getAfterImages = (projectId) => {
  const [afterImages, setAfterImages] = useState([]);

  useEffect(() => {
    if (projectId && projectFirestore) {
      const unsub = onSnapshot(collection(projectFirestore, `projects/${projectId}/after`), (snapshot) => {
        const images = [];
        snapshot.forEach((doc) => {
          images.push({
            ...doc.data(),
            id: doc.id,
            original: doc.data().imageUrl,
            thumbnail: doc.data().imageUrl,
          });
        });
        setAfterImages(images);
      });
      return () => unsub();
    }
    return null;
  }, [projectId]);

  return { afterImages };
};

export {
  getBeforeImages,
  getAfterImages,
};
