/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { projectFirestore } from './firebase';

const getVideos = (projectId) => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (projectId && projectFirestore) {
      const unsub = onSnapshot(collection(projectFirestore, `projects/${projectId}/videos`), (snapshot) => {
        const videosDocs = [];
        snapshot.forEach((doc) => {
          videosDocs.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        setVideos(videosDocs.sort(
          (first, second) => first.createdDateTime.localeCompare(second.createdDateTime),
        ));
      });
      return () => unsub();
    }
    return null;
  }, [projectId]);

  return { videos };
};

export default getVideos;
