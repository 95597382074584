/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { projectFirestore } from './firebase';

const getProjectDetails = (projectId) => {
  const [project, setProject] = useState();

  useEffect(() => {
    if (projectId && projectFirestore) {
      const unsub = onSnapshot(doc(projectFirestore, 'projects', projectId), (snapshot) => {
        if (snapshot.exists()) {
          setProject(snapshot.data());
        }
      });
      return () => unsub();
    }
    return null;
  }, [projectId]);

  return { project };
};

export default getProjectDetails;
